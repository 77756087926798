import { useQuery } from '@apollo/client'
import { convertTime, hasPermission } from '@common/src/common/helper'
import { GET_ALL_STORES } from '@common/src/lib/graphql/query/store'
import Pageheader from '@common/src/pageheader/pageheader'
import AvatarImage from '@pulse/components/common/ImageHolders/avatarImage'
import Pagination from '@pulse/components/common/Pagination/pagination'
import React, { useEffect, useState } from 'react'
import SearchBox from '@common/src/common/inputbox/searchBox'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import AlertModal from '@common/src/common/alertModal/alertModal'
import { useSelector } from 'react-redux'
import Loading from './components/Loading'
import NoItems from './components/NoItems'
import TableAction from './components/TableAction'
import TableHeader from './components/TableHeader'
import { storeStatusAction } from '@common/src/service/cloud/catalog'
import { INNER_PERMISSION } from '@common/src/constants/constants'
import FilterDropdown from '@common/src/common/inputbox/filterBox'
import { ALL_OPTION, STORE_OPTIONS, INITIAL_PAGINATION_PROPS } from '@pulse/constants/constants'

const StoreList = () => {
  const [list, setList] = useState([])
  const { permissions } = useSelector((state) => state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [storeStatus, setStoreStatus] = useState('');

  const [pagination, setPagination] = useState(INITIAL_PAGINATION_PROPS)
  const [searchQuery, setSearchQuery] = useState('')
  const [storeIdArray, setStoreIdArray] = useState([])
  const [info, setInfo] = useState('No stores found.')
  const toast = useToast()
  const { stores } = useSelector((state) => state.auth)
  useEffect(() => {
    const storesIdArrayData = stores.map((item) => item.value)
    if (storesIdArrayData.length > 0) {
      setStoreIdArray(storesIdArrayData)
    }
  }, [stores])
 
  const { data, refetch, loading } = useQuery(GET_ALL_STORES, {
    variables: {
      limit: pagination.limit,
      offset: searchQuery.length ? 0 : pagination.offset,
      search: searchQuery || undefined,
      filters: {
        storeIds: storeIdArray,
        ...((storeStatus !== ALL_OPTION.common.value && storeStatus !== "") && { isClosed: storeStatus })
      },
    },
    fetchPolicy : 'no-cache',
  })

  useEffect(() => {
    if (data) {
      setList(data.storeListQuery.rows)
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.storeListQuery.totalPages,
      }))
    } else {
      setList([])
      setInfo('No stores match your search.')
    }
  }, [data])

  const handleConfirm = () => {
    handleStoreUpdate(selectedItem)
    setIsModalOpen(false)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const onStatusToggleAlert = (record) => {
    setSelectedItem(record)
    setIsModalOpen(true)
  }

  const handleStoreUpdate = async (record) => {
    const newStatus = !record.isClosed
    const formData = { storeId: Number(record?.id) }
    try {
      await storeStatusAction(formData)
      setList((prevRows) => prevRows.map((row) => (row.id === record.id ? { ...row, isClosed: newStatus } : row)))
      toast.success(
        newStatus
          ? 'Store has been marked as unavailable successfully.'
          : 'Store has been marked as available successfully.'
      )
    } catch (error) {
      toast.error('Error updating store availability.')
    }
  }

  useEffect(() => {
    refetch({
      variables: {
        limit: pagination.limit,
        offset: pagination.offset,
        search: searchQuery || undefined,
        filters: {
          storeIds: storeIdArray,
          ...((storeStatus !== ALL_OPTION.common.value && storeStatus !== "") && { isClosed: storeStatus })
        },
      },
    })
    setPagination(INITIAL_PAGINATION_PROPS)
  }, [storeIdArray, storeStatus, searchQuery])
  return (
    <>
      <Pageheader currentpage="Stores" activepage="Availability " mainpage="Stores" />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">Stores</div>
              <div className="flex items-center">
                <FilterDropdown
                  className="mr-2 w-[200px]"
                  options={[ALL_OPTION.common, ...STORE_OPTIONS]}
                  value={STORE_OPTIONS.find((opt) => opt.value === storeStatus) || ALL_OPTION.common}
                  onChange={(e) => { setStoreStatus(e.value) }}
                />
                <SearchBox
                  value={searchQuery}
                  placeHolder="Search by store name / location"
                  width="w-[300px]"
                  classes='!py-2 !px-2 !mr-2'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <TableHeader
                    columns={[
                      'Sl.No',
                      'Store Name',
                      'Store Location',
                      'Services',
                      'Status (Open/Closed)',
                      'Operating Hours',
                      'Address',
                      'Actions',
                    ]}
                  />
                  <tbody>
                    {loading ? (
                      <Loading />
                    ) : (
                      <>
                        {list.length > 0 ? (
                          list.map((item, index) => (
                            <tr className="product-list" key={`${item.id}-${index}`}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + list.indexOf(item) + 1}</td>
                              <td>
                                <div className="flex items-center">
                                  <div className="me-2">
                                    <AvatarImage src={item?.photo} />
                                  </div>
                                  <div className="font-semibold">{item.name.en}</div>
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  <div className="font-semibold">{item?.address?.city?.en}</div>
                                </div>
                              </td>
                              <td>
                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                  {item?.services?.map((service) => (
                                    <li key={service}>{service.replace(/_/g, ' ')}</li>
                                  ))}
                                </ul>
                              </td>
                              <td>{item.isClosed ? 'Closed' : 'Open'}</td>
                              <td>{`${convertTime(item?.open)} to ${convertTime(item?.close)}`}</td>
                              <td>
                                <span style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                                  {item?.address?.address?.en}
                                </span>
                              </td>
                              <td>
                                {hasPermission(permissions, INNER_PERMISSION.STORE_STATUS_CHANGE) && (
                                  <TableAction
                                    item={item}
                                    status={!item.isClosed}
                                    onStatusToggleAlert={onStatusToggleAlert}
                                  />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoItems info={info} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} setPagination={setPagination} rows={list} innerPages={false} />
            </div>
            <AlertModal
              isOpen={isModalOpen}
              title="Confirm"
              message={`Are you sure you want to change the status to ${selectedItem?.isClosed ? 'active' : 'inactive'}?`}
              onConfirm={handleConfirm}
              onClose={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default StoreList
