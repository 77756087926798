import { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

import desktopLogo from '../assets/images/brand-logos/desktop-logo.png'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { forgot } from '@common/src/service/cloud/users'

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
})
const Forgot = () => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const submit = async (v) => {
    try {
      setLoading(true)
      await forgot({ email: v.email })
      setLoading(false)
      toast.success(`Password reset link sent successfully. Please check your email.`)
    } catch (error) {
      console.log(error)
      setLoading(false)
      toast.error(`Failed to send password reset link. Please try again later or contact support.`)
    }
  }

  return (
    <div className="container">
      <div className="flex justify-center authentication authentication-basic items-center h-full text-defaultsize text-defaulttextcolor">
        <div className="grid grid-cols-12">
          <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
          <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-8 col-span-12">
            <div className="my-[2.5rem] flex justify-center">
              <Link to={`${import.meta.env.BASE_URL}dashboard`}>
                <img src={desktopLogo} alt="logo" className="desktop-logo" />
                <img src={desktopLogo} alt="logo" className="desktop-dark" />
              </Link>
            </div>
            <div className="box">
              <div className="box-body !p-[3rem]">
                <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={submit}>
                  {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                    return (
                      <Form>
                        <p className="h5 font-semibold mb-2 text-center">Forgot password?</p>

                        <div className="grid grid-cols-12 gap-y-4">
                          <div className="xl:col-span-12 col-span-12">
                            <label htmlFor="signin-username" className="form-label text-default">
                              Email
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control form-control-lg w-full !rounded-md"
                              id="signin-username"
                              placeholder="Email"
                              onChange={(e) => setFieldValue('email', e.target.value)}
                            />
                            {touched.email && errors.email && (
                              <label
                                htmlFor={'signup-password'}
                                className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0"
                                style={{ color: 'red' }}
                              >
                                {errors.email}
                              </label>
                            )}
                          </div>

                          <div className="xl:col-span-12 col-span-12 grid mt-2">
                            <button
                              type="submit"
                              disabled={loading}
                              className="ti-btn ti-btn-primary !bg-primary !text-white !font-medium"
                            >
                              {loading ? <span className="me-2">Sending </span> : `Send Reset Link`}
                              {loading && (
                                <span className="loading">
                                  <i className="ri-loader-2-fill text-[1rem] animate-spin"></i>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="text-center">
                          <p className="text-[0.75rem] text-[#8c9097] dark:text-white/50 mt-4">
                            Remember your password?
                            <Link to={`${import.meta.env.BASE_URL}`} className="text-primary">
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
        </div>
      </div>
    </div>
  )
}

export default Forgot
