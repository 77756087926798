import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import Loader from '@common/src/common/loader/loader'
import { setSelectedStore } from '../../../redux/modules/auth/action'
import { USER_TYPES } from '@common/src/constants/constants'
import { ALL_OPTION } from '@pulse/constants/constants'

const StoreDrop = () => {
  const dispatch = useDispatch()
  const { stores = [], loading = true, user, selectedStore } = useSelector((state) => state.auth || {})
  const [options, setOptions] = React.useState([ALL_OPTION.stores, ...stores])

  // Do not show store dropdown if user is on store page
  const currentPage = window.location.pathname;
  const onChange = (obj) => {
    dispatch(setSelectedStore(obj))

  }
  useEffect(() => {
    if (currentPage === '/menu/' || currentPage === '/modifiers/') {
      setOptions(stores)
    } else {
      setOptions([ALL_OPTION.stores, ...stores])
    }
  }, [currentPage])

  if (currentPage === '/store/') return null

  return (
    <>
      {loading && <Loader />}
      {!loading && user?.userType === USER_TYPES.PULSE_SUB_ADMIN && (
        <Select
          className="ti-form-select rounded-sm !p-0 min-w-[300px]"
          placeholder="Choose Store"
          options={options}
          defaultValue={selectedStore || options[0]}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default StoreDrop
