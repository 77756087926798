import {
  getDocs,
  query,
  collectionGroup,
} from 'firebase/firestore'
import logger from '@common/src/lib/logger'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'

export const getStore = async () => {
  try {
    let snap

    const storesQuery = query(collectionGroup(db, COLLECTIONS.STORES));
    snap = await getDocs(storesQuery)

    // Map the documents to the desired format
    const data = snap.docs.map((doc) => ({
      value: doc?.id,
      label: doc?.data()?.name?.en,
      companyId: doc.ref.path.split('/')[1], // Extract `companyId` from the path
    }));
    return data
  } catch (error) {
    logger.error('Error fetching Store data:', error)
    return [] // Return an empty array in case of error
  }
}
