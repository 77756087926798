
import React from 'react'
import PropTypes from 'prop-types'


const SearchBox = ({ placeHolder, value, onChange, onKeyDown, classes = '!py-1 !px-2 !mb-3', width = 'w-[200px]' }) => {
    return (
        <div className={`relative flex ms-auto ${width}`}>
            <input
                type="text"
                value={value}
                onChange={onChange}
                className={`form-control pr-10 ${classes}`}
                placeholder={placeHolder || "Search..."}
                onKeyDown={(e) => onKeyDown?.(e)}
            />
            {value && (
                <button
                    type="button"
                    className='absolute'
                    style={{
                        height: '90%',
                        right: '5px',
                        background: '#fff',
                        padding: '0px 10px',
                        margin: '1px'
                    }}
                    onClick={() => onChange({ target: { value: '' } })}
                >
                    ✕
                </button>
            )}
        </div>

    )
}

SearchBox.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
    classes: PropTypes.string,
    width: PropTypes.string,
    onKeyDown: PropTypes.func
}

export default SearchBox