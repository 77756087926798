import React, { useEffect, useState } from 'react'
import { collection, onSnapshot, orderBy, query, where, limit } from 'firebase/firestore'
import { db } from '../../../firebase/firebaseapi'
import OrderTable from './orderTable';
import Loader from '@common/src/common/loader/loader';
import PropTypes from 'prop-types';
import { ALL_OPTION } from '@pulse/constants/constants';

const LiveOrders = ({ store }) => {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedFilterStatusValue, setSelectedFilterStatusValue] = useState(ALL_OPTION.status);


    useEffect(() => {
        // fetch orders on mount
        fetchLiveOrders()
    }, [store, selectedFilterStatusValue])

    const fetchLiveOrders = () => {
        // Constructing the query dynamically
        const ordersRef = collection(db, "orders");
        const filters = [];
        if (store?.value) {
            filters.push(where("storeId", "==", parseInt(store?.value)));
        }
        if (selectedFilterStatusValue?.value !== 'all') {
            filters.push(where("orderStatus", "==", selectedFilterStatusValue?.value));
        }
        const liveOrdersQuery = query(ordersRef, ...filters, orderBy("orderDate", "desc"), limit(100));
        // Listening to real-time updates
        const unsubscribe = onSnapshot(liveOrdersQuery, (querySnapshot) => {
            const ordersList = [];
            querySnapshot.forEach((doc) => {
                ordersList.push({ ...doc.data(), id: doc.id });
            });
            setOrders(ordersList); // Update state with live data
            // set loading state to false
            setLoading(false)
        });

        return unsubscribe; // Return the unsubscribe function to stop listening
    };


    const onAllFilter = (selectedStartDate, selectedEndDate, selectedStatus, storesSelected) => {

        // Clear all filters
        setSelectedFilterStatusValue(ALL_OPTION.status);
        if (storesSelected?.value) {
            setSelectedStores(storesSelected)
        }
        if (selectedStatus?.value) {
            setSelectedFilterStatusValue(selectedStatus)
        }

    }
    if (loading) return <Loader />
    return (
        <div className="mt-6 table-responsive">
            <OrderTable
                orders={orders}
                timeStamp={true}
                onFilter={onAllFilter}
                selectedFilterStatus={selectedFilterStatusValue}
            />
        </div>
    )
};

LiveOrders.propTypes = {
    store: PropTypes.object,
}
export default LiveOrders;
