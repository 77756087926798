import {
  getDocs,
  query,
  collectionGroup} from 'firebase/firestore'
import logger from '@common/src/lib/logger'
import { db } from '../../firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'

export const getStore = async (all, ids) => {
  try {
    let snap
    let docs

    if (all) {
      const storesQuery = query(collectionGroup(db, COLLECTIONS.STORES));
      snap = await getDocs(storesQuery)
      docs= snap.docs;
    
    } else if (ids && Array.isArray(ids) && ids.length > 0) {
      const storesQuery = query(collectionGroup(db, COLLECTIONS.STORES));
      // const storesQuery = query(collectionGroup(db, COLLECTIONS.STORES), where(documentId(), 'in', ids) );
      snap = await getDocs(storesQuery)
      docs= snap.docs
      .filter((doc) => ids.includes(doc.id))
      
    } else {
      logger.warn('No valid ids provided for filtering.')
      return [] // Return an empty array if no valid ids are provided
    }
    
 // Map the documents to the desired format
    const data = docs.map((doc) => ({
      value: parseInt(doc?.id),
      // label: doc?.data()?.name?.en,
      label: doc?.data()?.name?.en + ' , ' + doc?.data()?.address?.city?.en,
      companyId: doc.ref.path.split('/')[1], // Extract `companyId` from the path
    }));
   
    return data
  } catch (error) {
    logger.error('Error fetching Store data:', error)
    return [] // Return an empty array in case of error
  }
}
