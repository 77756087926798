import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import InputBox from '@common/src/common/inputbox/inputbox'
import Toggle from '@common/src/common/Toggle/Toggle'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import { createUser, updateUser } from '../service/cloud/users'
import OffCanvasForm from '../common/offcanvas/offCanvasForm'
import { getAllRoles, getUserRole } from '../service/firebase/roles'
import { getCompany } from '../service/firebase/company'
import { getStore } from '../service/firebase/store'
import { ROLE, USER_TYPES } from '../constants/constants'
import Loader from '../common/loader/loader'

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First Name should not be less than 3 characters')
    .max(30, 'First Name should not exceed 30 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .min(3, 'Last Name should not be less than 3 characters')
    .max(30, 'Last Name should not exceed 30 characters')
    .required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  role: Yup.string().required('Role is required'),
  allCompanies: Yup.bool(),
  companies: Yup.array().when('allCompanies', {
    is: (value) => value === false && import.meta.env.VITE_PORTAL == ROLE.ROLE_TYPE_HUB, // if 'allCompanies' is false, make 'company' required
    then: () => Yup.array().min(1, 'At least one company must be selected').required('Company is required'),
  }),
  allStores: Yup.bool(),
  stores: Yup.array().when('allStores', {
    is: (value) => value === false && import.meta.env.VITE_PORTAL == ROLE.ROLE_TYPE_PULSE, // if 'allStores' is false, make 'store' required
    then: () => Yup.array().min(1, 'At least one store must be selected').required('Store is required'),
  }),
})

const AddUser = ({ id, data, isEdit, onComplete }) => {
  const [role, setRole] = useState([])
  const [company, setCompany] = useState([])
  const [store, setStore] = useState([])
  const [userRole, setUserRole] = useState({})
  const [loading, setLoading] = useState(true)
  const toast = useToast()

  const onSubmit = async (values, actions) => {
    try {
      if (import.meta.env.VITE_PORTAL === ROLE.ROLE_TYPE_HUB) {
        values.stores = values.stores || []
        values.allStores = !!values.allStores
        values.userType = USER_TYPES.HUB_SUB_ADMIN
      } else {
        values.companies = values.companies || []
        values.allCompanies = !!values.allCompanies
        values.userType = USER_TYPES.PULSE_SUB_ADMIN
      }
      values.type = import.meta.env.VITE_PORTAL
      
      if (isEdit) {
        await updateUser({ id: data.id, ...values })
        toast.success('User Updated Successfully')
      } else {
        await createUser(values)
        toast.success('User Created Successfully')
      }
      actions?.resetForm()
      onComplete?.()
    } catch (error) {
      console.error('Error saving role: ', error)
      toast.error(error.message || 'Error saving user')
    } finally {
      actions?.setSubmitting(false)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true) // Set loading to true before fetching data

      try {
        const fetchUserRolesPromise = isEdit ? getUserRole(data.id, import.meta.env.VITE_PORTAL) : Promise.resolve(null)
        const fetchRolePromise = getAllRoles(80)
        const fetchCompanyPromise = getCompany()

        // Execute all the fetches concurrently
        const [usrRole, userRoles, company] = await Promise.all([
          fetchUserRolesPromise,
          fetchRolePromise,
          fetchCompanyPromise,
        ])

        // Process roles
        const getRoleData = userRoles?.data?.map((doc) => ({
          label: doc.name,
          value: doc.id,
        }))
        setRole(getRoleData)

        // Process company
        const getCompanyData = company?.map((doc) => ({
          label: doc?.name?.en,
          value: doc.id,
        }))
        setCompany(getCompanyData)

        if (import.meta.env.VITE_PORTAL == ROLE.ROLE_TYPE_PULSE) {
          setStore(await getStore())
        }

        // Set user role if it's edit mode
        if (usrRole) {
          setUserRole(usrRole)
        }
      } catch (error) {
        console.error('Error fetching data: ', error)
      } finally {
        setLoading(false) // Set loading to false after all data is fetched
      }
    }

    fetchData()
  }, [isEdit, data?.id])

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <OffCanvasForm
          title={isEdit ? 'Edit User' : 'Create User'}
          initialValues={{
            firstName: isEdit ? data.firstName : '',
            lastName: isEdit ? data.lastName : '',
            email: isEdit ? data.email : '',
            role: isEdit ? userRole?.roleData?.id : '',
            allCompanies: isEdit ? userRole.allCompanies : false,
            companies: isEdit ? userRole.companies : [],
            allStores: isEdit ? userRole.allStores : false,
            stores: isEdit ? userRole.stores : [],
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          okText={isEdit ? 'Update' : 'Add'}
          closeText={isEdit ? 'Close' : 'Cancel'}
          id={id}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, handleChange, handleBlur, errors, touched }) => {
            return (
              <div className="grid grid-cols-12 gap-4">
                <div className="mt-6 col-span-12">
                  <InputBox
                    id="firstName"
                    label="First Name"
                    placeholder="First Name"
                    value={values.firstName}
                    maxLength={30}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/^\s+/, '') // Remove leading spaces
                      setFieldValue('firstName', sanitizedValue) // Update Formik's value
                    }}
                    onBlur={handleBlur}
                    errorText={touched.firstName && errors.firstName ? errors.firstName : ''}
                    required={true}
                  />
                </div>
                <div className="mt-6 col-span-12">
                  <InputBox
                    id="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    value={values.lastName}
                    maxLength={30}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/^\s+/, '') // Remove leading spaces
                      setFieldValue('lastName', sanitizedValue) // Update Formik's value
                    }}
                    onBlur={handleBlur}
                    errorText={touched.lastName && errors.lastName ? errors.lastName : ''}
                    required={true}
                  />
                </div>
                <div className="mt-6 col-span-12">
                  <InputBox
                    id="email"
                    label="Email"
                    placeholder="Email"
                    value={values.email}
                    maxLength={50}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.toLowerCase().replace(/^\s+/, '') // Remove leading spaces and convert to lowercase
                      setFieldValue('email', sanitizedValue) // Update Formik's value
                    }}
                    onBlur={handleBlur}
                    disabled={isEdit}
                    errorText={touched.email && errors.email ? errors.email : ''}
                    required={true}
                  />
                </div>
                {import.meta.env.VITE_PORTAL == ROLE.ROLE_TYPE_HUB && (
                  <>
                    <div className="mt-6 col-span-12">
                      <Toggle
                        label="All Companies"
                        value={values.allCompanies}
                        onChange={(e) => setFieldValue('allCompanies', e.target.checked)}
                      />
                    </div>
                    {!values.allCompanies && (
                      <div className="mt-6 col-span-12">
                        <SelectBox
                          id="company"
                          label="Choose companies"
                          options={company}
                          isMulti
                          name="companies"
                          defaultValue={company.find((option) => option.value === values.companies)}
                          onChange={(e) =>
                            setFieldValue(
                              'companies',
                              e?.map((option) => option.value)
                            )
                          }
                          errorText={touched.companies && errors.companies ? errors.companies : ''}
                        />
                      </div>
                    )}
                  </>
                )}
                {import.meta.env.VITE_PORTAL == ROLE.ROLE_TYPE_PULSE && (
                  <>
                    <div className="mt-6 col-span-12">
                      <Toggle
                        label="All Stores"
                        value={values.allStores}
                        onChange={(e) => setFieldValue('allStores', e.target.checked)}
                      />
                    </div>
                    {!values.allStores && (
                      <div className="mt-6 col-span-12">
                        <SelectBox
                          id="store"
                          label="Choose Stores"
                          options={store}
                          isMulti
                          name="stores"
                          defaultValue={store.filter((option) => values.stores?.includes(option?.value))}
                          onChange={(e) =>
                            setFieldValue(
                              'stores',
                              e?.map((option) => option.value)
                            )
                          }
                          errorText={touched.stores && errors.stores ? errors.stores : ''}
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="mt-6 col-span-12">
                  <SelectBox
                    id="role"
                    label="Choose role"
                    options={role}
                    name="role"
                    value={role.find((option) => option.value === values.role)}
                    onChange={(e) => setFieldValue('role', e.value)}
                    required={true}
                    errorText={touched.role && errors.role ? errors.role : ''}
                  />
                </div>
              </div>
            )
          }}
        </OffCanvasForm>
      )}
    </>
  )
}
AddUser.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    id: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    allCompanies: PropTypes.bool.isRequired,
    companies: PropTypes.arrayOf(PropTypes.string).isRequired,
    role: PropTypes.string.isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default AddUser
