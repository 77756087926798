import PropTypes from 'prop-types'
import { useMemo } from 'react'

function formatAssignedPermissions(assigned, master) {
  const result = []

  function traverse(node, path = []) {
    const currentPath = [...path, node.label]

    // If the node has children, process them
    if (node.children) {
      const childResults = node.children
        .map((child) => traverse(child, currentPath))
        .flat()
        .filter(Boolean) // Remove nulls

      // If any child matches, exclude the parent
      return childResults.length > 0 ? childResults : null
    }

    // If the current node is assigned, return its path
    return assigned.includes(node.value) ? currentPath.join('->') : null
  }

  master.forEach((node) => {
    const paths = traverse(node)
    if (paths) {
      result.push(...(Array.isArray(paths) ? paths : [paths]))
    }
  })

  return result
}

const Permission = ({ permissionArray, permissions }) => {
  const formatted = useMemo(
    () => formatAssignedPermissions(permissionArray, permissions),
    [permissionArray, permissions]
  )

  return (
    <>
      {formatted.map((p) => (
        <span key={p} className="badge bg-success/10 text-success" style={{ display: 'block', textAlign: 'left' }}>
          {p}
        </span>
      ))}
    </>
  )
}

Permission.propTypes = {
  permissionArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
}

export default Permission
