import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql`
  query getAllProductsByStoreId($search: String, $limit: Int, $offset: Int,$storeId: Int, $filter: ProductFilterInput) {
    productListByStoreQuery(search: $search, limit: $limit, offset: $offset,storeId: $storeId, filter: $filter) {
      limit
      offset
      totalPages
      totalRecords

      rows {
        id
        name {
          ar
          en
        }
        description {
          ar
          en
        }
        posId
        type
        defaultSalesPrice
        image
        company{
          name {
            ar
            en
          }
        }
         categoryInfo{
         name{
         en
         ar
            }
         } 
        status
        isAvailable
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query productDetailsQuery($id: Int!) {
    productQuery(id: $id) {
      id
      companyId
      categoryId
      type
      posId
      isRecommend
      sortOrder
      services
      chefPick
      productModGroups{
       id
       quantity
      modGroupName{en}
      }
       
      chefPickImages{
       home{
        web
        mobile
       }
       category{
        web
        mobile
       }
      }
      marketPrice
      default
      name {
        ar
        en
      }
      description {
        ar
        en
      }
      price
      marketPrice
      defaultSalesPrice
      preOrderingTiming
      image
      availability{
        date{
          start
          end
          option
        }
        day{
          days
          option
        }
        time{
          start
          end
          option
        }
      }
      timed
    }
  }
`
export const GET_COMPANY_STORE_PRODUCTS = gql`
  query companyStoreProductsQuery($companyId: Int!, $productId: Int, $search: String, $limit: Int, $offset: Int) {
    companyStoreProductsQuery(companyId: $companyId, productId: $productId, search: $search, limit: $limit, offset: $offset) {
      rows {
        id
        name {
          ar
          en
        }
        products {
          productId
          isAvailable
          prices {
            DELIVERY
            TAKEAWAY
            GLOBAL_TAKEAWAY
            GLOBAL_DELIVERY
          }
        }
      }
    }
  }
`
