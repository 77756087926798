import { gql } from '@apollo/client';

export const GET_ALL_MODIFIERS = gql`
  query modifierListQuery($companyId:Int,$search: String, $limit: Int, $offset: Int, $storeId:Int, $status: String, $filter: ModifierFiltersInput) {
    modifierQuery(companyId:$companyId, storeId:$storeId,search: $search, limit: $limit, offset: $offset, status:$status, filter:$filter) {
      limit
      offset
      totalPages
      totalRecords
      rows {
        id
        status
        modItemName {
          ar
          en
        }
        posId
        defaultSalesPrice
        foodType
        image
        isAvailable
        company{
          name {
            ar
            en
          }
        }
     }
    }
  }
`;
