import React from 'react'
import { useSelector } from 'react-redux'
import { GET_ALL_MODIFIERS } from '@common/src/lib/graphql/query/modifier'
import { modifierStatusAction } from '@common/src/service/cloud/catalog'
import GenericAvailabilityList from './availability'
import TableAction from './components/TableAction'
import AvatarImage from '@pulse/components/common/ImageHolders/avatarImage'
import { INNER_PERMISSION } from '@common/src/constants/constants'
import { hasPermission } from '@common/src/common/helper'

const ModifierList = () => {
  const { permissions } = useSelector((state) => state.auth)
  return (
    <GenericAvailabilityList
      query={GET_ALL_MODIFIERS}
      queryName="modifierQuery"
      columns={['Sl.No', 'Item', 'POS ID', 'Action']}
      statusAction={modifierStatusAction}
      pageTitle="Modifier"
      searchPlaceholder="Search by modifier name / POS ID"
      activeOnly={false}
    >
      {({ item, pagination, rows, onStatusToggleAlert }) => {
        return (
          <tr className="product-list" key={item.id}>
            <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(item) + 1}</td>
            <td>
              <div className="flex items-center">
                <div className="me-2">
                  <AvatarImage src={item?.image} />
                </div>
                <div className="font-semibold">{item?.modItemName?.en}</div>
              </div>
            </td>
           
            <td>{item.posId}</td>
            <td>
              {hasPermission(permissions, INNER_PERMISSION.MODIFIER_STATUS_CHANGE) && (
                <TableAction
                  item={item}
                  status={item.isAvailable}
                  onStatusToggleAlert={() =>
                    onStatusToggleAlert({ modifierId: Number(item.id), status: !item.isAvailable }, item)
                  }
                />
              )}
            </td>
          </tr>
        )
      }}
    </GenericAvailabilityList>
  )
}

export default ModifierList
