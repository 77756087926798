import PropTypes from 'prop-types';
import Toggle from '@common/src/common/Toggle/Toggle'

const TableAction = ({ item, status, onStatusToggleAlert }) => {
  return (
      <div className="flex items-center space-x-1 rtl:space-x-reverse">
        <Toggle
          label=""
          value={status}
          onChange={() => onStatusToggleAlert(item)}
        />
      </div>
  );
};

TableAction.propTypes = {
  item: PropTypes.object.isRequired,
  onStatusToggleAlert: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired
};

export default TableAction;