const Loading = () => {
  return (
    <tr>
    <td colSpan={8} className="text-center">
      <div className="d-flex justify-content-center">
        <div className="spinner-border">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </td>
  </tr>
  );
};

export default Loading;
