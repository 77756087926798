import {
    collection,
    getDocs,
    query,
    orderBy,
} from 'firebase/firestore'
import logger from '@common/src/lib/logger'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'

/**
 * Retrieves the order history for a given order ID.
 *
 * @param {string} orderId - The order ID.
 *
 * @returns {Promise<Array<object>>} - A promise that resolves with a list of order history objects, or an empty list if there is an error.
 */
export const getOrderHistory = async (orderId) => {
    try {
        const q = query(collection(db, COLLECTIONS.ORDERS, orderId, COLLECTIONS.ORDER_HISTORY), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const orderHistoryArr = [];
        querySnapshot.forEach((doc) => {
            orderHistoryArr.push({ id: doc.id, ...doc.data() });
        });
        return orderHistoryArr;
    } catch (error) {
        logger.error('Error retrieving order history:', error);
        return [];
    }
};
/**
 * Retrieves the payment history for a given order ID.
 *
 * @param {string} orderId - The order ID.
 *
 * @returns {Promise<Array<object>>} - A promise that resolves with a list of payment history objects, or an empty list if there is an error.
 */
export const getPaymentHistory = async (orderId) => {
    try {
        const q = query(collection(db, COLLECTIONS.ORDERS, orderId, COLLECTIONS.PAYMENT_HISTORY), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const paymentHistoryArr = [];
        querySnapshot.forEach((doc) => {
            paymentHistoryArr.push({ id: doc.id, ...doc.data() });
        });
        return paymentHistoryArr;
    } catch (error) {
        logger.error('Error retrieving order history:', error);
        return [];
    }
};