import React from 'react';
import PropTypes from 'prop-types';

const AlertModal = ({ isOpen, title, message, onConfirm, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-1/3">
        <h2 className="text-lg font-bold mb-4 text-center">{title}</h2>
        <p className="mb-6 text-center">{message}</p>
         <div className="mt-4 flex justify-center space-x-4">
              <button onClick={onClose} className="bg-danger text-white px-4 py-2 rounded hover:bg-red-700">
                No
              </button>
              <button
                onClick={onConfirm}
                className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Yes
              </button>
            </div>
      </div>
    </div>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default AlertModal;
