import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import 'preline/preline'
import Footer from '@common/src/footer/footer'
import Sidebar from '../shared/components/sidebar/sidebar'
import Switcher from '../components/common/switcher/switcher'
import Header from '../shared/components/header/header'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import Tabtotop from '../components/common/tabtotop/tabtotop'
import ErrorBoundary from './ErrorBoundary'

function App() {
  const [myClass] = useState('')

  // const Bodyclickk = () => {
  //   if (localStorage.getItem("ynexverticalstyles") == "icontext") {
  //     setMyClass("");
  //   }
  //   if (window.innerWidth > 992) {
  //     let html = document.documentElement;
  //     if (html.getAttribute('icon-overlay') === 'open') {
  //         html.setAttribute('icon-overlay' ,"");
  //     }
  //   }
  // }

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit()
    }
  }, [])
  return (
    <ErrorBoundary>
      {/* <Loader/> */}
      {/* <Provider store={store}> */}
      <HelmetProvider>
        <Helmet
          htmlAttributes={{
            lang: 'en',
            dir: 'ltr',
            'data-menu-styles': 'dark',
            class: 'light',
            'data-nav-layout': 'vertical',
            'data-header-styles': 'light',
            'data-vertical-style': 'overlay',
            loader: 'disable',
            'data-icon-text': myClass,
          }}
        />
        <Switcher />

        <div className="page">
          <Header />
          <Sidebar />
          <div className="content main-index"
            style={{ minHeight: `calc(100vh - 110px)` }}>
            <div
              className="main-content"
            // onClick={Bodyclickk}
            >
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
        <Tabtotop />
      </HelmetProvider>
      {/* </Provider> */}
    </ErrorBoundary>
  )
}

export default Sentry.withProfiler(App, { name: 'Hub' })
