import PropTypes from 'prop-types';

const TableHeader = ({ columns }) => (
  <thead>
    {columns.map((column) => (
      <th key={column} scope="col" className="text-start">
        {column}
      </th>
    ))}
  </thead>
);

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default TableHeader