import React from 'react'
import { useSelector } from 'react-redux'
import { GET_ALL_PRODUCTS } from '@common/src/lib/graphql/query/product'
import { productStatusAction } from '@common/src/service/cloud/catalog'
import AvatarImage from '@pulse/components/common/ImageHolders/avatarImage'
import { hasPermission } from '@common/src/common/helper'
import GenericAvailabilityList from './availability'
import TableAction from './components/TableAction'
import { INNER_PERMISSION } from '@common/src/constants/constants'

const MenuList = () => {
  const { permissions } = useSelector((state) => state.auth)
  return (
    <GenericAvailabilityList
      query={GET_ALL_PRODUCTS}
      queryName="productListByStoreQuery"
      columns={['Sl.No', 'Product Name', 'Type', 'Price', 'Category', 'POS ID', 'Action']}
      statusAction={productStatusAction}
      pageTitle="Menu"
      searchPlaceholder="Search by product name / POS ID"
      keys={['item?.modItemName?.en', 'en']}
    // onStatusToggleAlert={onStatusToggleAlert}
    >
      {({ item, pagination, rows, onStatusToggleAlert }) => {
        return (
          <tr className="product-list" key={item.id}>
            <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(item) + 1}</td>
            <td>
              <div className="flex items-center">
                <div className="me-2">
                  <AvatarImage src={item?.image?.[0]} />
                </div>
                <div className="font-semibold">{item?.name?.en}</div>
              </div>
            </td>
            <td>
              <span className="badge bg-info text-white">{item?.type?.replace(/_/g, ' ')}</span>
            </td>
            <td>{item?.defaultSalesPrice}</td>
            <td>{item?.categoryInfo?.name?.en || item?.categoryInfo?.name?.ar}</td>
            <td>{item?.posId}</td>
            <td>
              {hasPermission(permissions, INNER_PERMISSION.MENU_STATUS_CHANGE) && (
                <TableAction
                  item={item}
                  status={item?.isAvailable}
                  onStatusToggleAlert={() =>
                    onStatusToggleAlert({ productId: Number(item.id), status: !item.isAvailable }, item)
                  }
                />
              )}
            </td>
          </tr>
        )
      }}
    </GenericAvailabilityList>
  )
}

export default MenuList
