import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_ORDERS } from '@common/src/lib/graphql/query/orders';
import OrderTable from './orderTable';
import Loader from '@common/src/common/loader/loader';
import PropTypes from 'prop-types';
import { ALL_OPTION } from '@pulse/constants/constants';
import { calcOffset } from '@common/src/common/helper';


const AllOrders = ({ store }) => {
    const [allOrders, setAllOrders] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [selectedFilterStatusValue, setSelectedFilterStatusValue] = useState(ALL_OPTION.status);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [pagination, setPagination] = useState({
        limit: 100,
        totalPages: 0,
        currentPage: 1,
        offset: 0,
        totalRecords: 0
    })
    const [searchQuery, setSearchQuery] = useState('');
    const { data, loading, refetch, error } = useQuery(GET_ALL_ORDERS, {
        variables: {
            offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
            limit: pagination.limit,
            search: searchQuery || undefined,
            filters: {
                storeId: store?.value ? store.value.toString() : undefined,
                orderStatus: filterStatus || undefined,
                startDate: filterStartDate || undefined,
                endDate: filterEndDate || undefined
            }
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (data && !loading && !error) {
            setAllOrders(data.orderListQuery.rows);
            setPagination((prevPagination) => ({
                ...prevPagination,
                totalPages: data.orderListQuery.totalPages,
            }))
        } else {
            setAllOrders([])
        }
    }, [data, loading, error]);

    // Function to handle the search term
    const handleSearchChange = (newSearchItem) => {
        setSearchQuery(newSearchItem);
    };
    useEffect(() => {
        refetch({
            variables: {
                offset: calcOffset(searchQuery, pagination.currentPage, pagination.limit, pagination.totalRecords),
                limit: pagination.limit,
                ...(searchQuery && { search: searchQuery }),
                ...(store && { storeId: store?.value.toString() }),
            },
        });
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1
        }))
    }, [store, searchQuery]);

    const onAllFilter = (selectedStartDate, selectedEndDate, selectedStatus) => {

        setFilterStatus('');
        setSelectedFilterStatusValue(ALL_OPTION.status);
        setFilterStartDate('');
        setFilterEndDate('');
        if (selectedStatus?.value) {
            setFilterStatus(selectedStatus?.value === 'all' ? '' : selectedStatus?.value)
            setSelectedFilterStatusValue(selectedStatus)
        }
        if (selectedStartDate !== '') {
            setFilterStartDate(selectedStartDate)
        }
        if (selectedEndDate !== '') {
            setFilterEndDate(selectedEndDate)
        }
    }

    if (loading) return <Loader />
    return (
        <div className="mt-6 table-responsive">
            <OrderTable
                onFilter={onAllFilter}
                orders={allOrders}
                searchQuery={handleSearchChange}
                searchData={searchQuery}
                selectedFilterStatus={selectedFilterStatusValue}
                filterStartDate={filterStartDate}
                pagination={pagination}
                filterEndDate={filterEndDate}
                setPagination={setPagination}
            />
        </div>
    )
}
AllOrders.propTypes = {
    store: PropTypes.object,
}
export default AllOrders;
