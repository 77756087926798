import PropTypes from 'prop-types';

const NoItems = ({ info }) => {
  return (
    <tr>
      <td colSpan={8} className="text-center">
        <div className="alert alert-info">{info}</div>
      </td>
    </tr>
  )
}
NoItems.propTypes = {
  info: PropTypes.string.isRequired,
}

export default NoItems